import TransferRequestHelper from './helper/TransferRequestHelper';

onLoad(($) => {
  if (!$('.ds-qr-code-pix-received-new').length) return;

  const transferRequestHelper = new TransferRequestHelper();

  const $keySelect = $('#qr_code_pix_received_key_id');
  const $createButton = $('.JS-create-qrcode-btn');

  const verifyAndToggleButton = () => {
    const inputs = [$keySelect.val()];
    transferRequestHelper.verifyInputs(inputs, $createButton);
  };

  verifyAndToggleButton();

  $keySelect.on('change', verifyAndToggleButton);
});
