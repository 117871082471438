import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

onLoad(($) => {
  const $form = $('#new_user');
  const $loginFields = $form.find('#JS-login-fields');
  const $twoFactorFields = $form.find('#JS-two-factor-fields');
  const $otpAttempt = $twoFactorFields.find('#user_otp_attempt');
  const $loginUnlockable = $('.JS-login-unlockable');
  const dataUnlockable = $loginUnlockable.data('unlockable');

  let otpRequired = true;
  let requestId = null;

  function alertRecaptcha(jsonData) {
    $.ajax({
      url: '/users/alert_recaptcha',
      type: 'POST',
      data: jsonData,
      dataType: 'html',
    });
  }

  function prepareTwoFactorField() {
    $loginFields.hide();
    $twoFactorFields.removeClass('d-none');
    $otpAttempt.prop('disabled', false);
  }

  function checkTwoFactorAndRecaptcha(jsonData) {
    return new Promise((resolve) => {
      $.ajax({
        url: '/users/two-factor-verification',
        type: 'POST',
        data: jsonData,
        dataType: 'json',
        success: (response) => {
          resolve(response);
        },
      });
    });
  }

  function setFingerprintCounter() {
    return new Promise((resolve) => {
      $.ajax({
        url: '/users/fingerprint_counter',
        type: 'POST',
        data: { request_id: requestId },
        dataType: 'json',
        success: (response) => {
          resolve(response);
        },
      });
    });
  }

  function requestFingerprint() {
    return new Promise((resolve, reject) => {
      FingerprintJS.load({ apiKey: process.env.FINGERPRINT_PUBLIC_KEY })
        .then(fp => fp.get())
        .then((result) => {
          requestId = result.requestId;
          setFingerprintCounter();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function handleModalUnlocking() {
    let prevent = false;
    const text = `O seu login foi bloqueado devido ao excesso de tentativas incorretas.
    <br><br>Você pode realizar o desbloqueio clicando no botão abaixo. Você receberá um email de redefinição de senha.
    Após redefini-la, seu login será desbloqueado ou aguarde alguns minutos e tente novamente.`;
    const url = `/users/password?user[email]=${dataUnlockable}`;

    Swal.fire({
      title: 'Login bloqueado',
      html: text,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Desbloquear',
      preConfirm: () => {
        if (!prevent) {
          prevent = true;
          Swal.disableButtons();
          $.post(url).then(() => window.location.reload());
        }

        return false;
      },
    });
  }

  $form.on('submit', (e) => {
    if (requestId === null && fingerprintEnabled) {
      e.preventDefault();

      requestFingerprint()
        .then(() => {
          $('<input />').attr({
            type: 'hidden',
            id: 'request_id',
            name: 'request_id',
            value: requestId,
          }).appendTo($form);

          $form.submit();
        })
        .catch(() => {
          requestId = 'error';
        });

      return false;
    }

    if ($otpAttempt.is(':disabled') && otpRequired === true) {
      e.preventDefault();

      const jsonData = $form.serialize();

      checkTwoFactorAndRecaptcha(jsonData)
        .then((response) => {
          if (response.otp_required === true && response.is_valid_recaptcha) {
            prepareTwoFactorField();
          } else {
            if (!response.is_valid_recaptcha) alertRecaptcha(jsonData);

            otpRequired = false;
            $form.submit();
          }
        });

      return false;
    }

    return true;
  });

  if (!$loginUnlockable.length) return;
  handleModalUnlocking();
});
