onLoad(($) => {
  const documentNumberField = $('#JS-login_document_number_field');

  if (documentNumberField.length > 0) {
    $('form').on('submit', () => {
      const documentNumberInput = documentNumberField.val();
      const documentNumberWithoutMask = documentNumberInput.replace(/[^\d]/g, '');
      documentNumberField.val(documentNumberWithoutMask);
    });
  }
});
