onLoad(($) => {
  const $exploreCard = $('.explore-card');
  if (!$exploreCard.length) return;

  const $exploreCardContent = $('.explore-card-content-container');
  const offsetValue = -90;

  $exploreCard.on('click', (e) => {
    const $clickedCard = $(e.currentTarget);
    const id = $clickedCard.attr('id');
    const $content = $exploreCardContent.filter(`#${id}`);
    const isActive = $clickedCard.hasClass('active');

    $exploreCard.removeClass('active');
    $exploreCardContent.addClass('d-none');

    if (!isActive) {
      $clickedCard.addClass('active');
      $content.removeClass('d-none');
      $('html,body').animate({ scrollTop: $content.offset().top + offsetValue }, 'slow');
    }
  });
});
